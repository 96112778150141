import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Icons
import ChevronDown from '../../../assets/img/chevron-down.svg'

const FaqsItem = (props) => {
  const { header, body, isOpen: isOpenOnRender, canAnimate } = props

  const bodyElement = useRef(null)

  const [isOpen, setIsOpen] = useState(isOpenOnRender)
  const [bodyHeight, setBodyHeight] = useState(0)

  /**
   * Literally just update the height of the body
   * @return {Void}
   */
  const updateBodyHeight = () => {
    if (bodyElement.current) {
      setBodyHeight(bodyElement.current.offsetHeight)
    }
  }

  /**
   * Update the height of the body when the component is mounted, plus add some
   * event listeners. Remove on unmount.
   */
  useEffect(() => {
    updateBodyHeight()
    window.addEventListener('resize', updateBodyHeight)

    return () => {
      window.removeEventListener('resize', updateBodyHeight)
    }
  }, [bodyElement])

  /**
   * Toggles the visibility of the body
   * @param  {Object} event
   * @return {Void}
   */
  const handleClick = (event) => {
    event.preventDefault()

    // Due to how we render some FaqItem components, re-check the height just
    // as we trigger it to open
    if (!isOpen) {
      updateBodyHeight()
    }

    setIsOpen(!isOpen)
  }

  return (
    <li className={classNames({ 'pb-6': isOpen })}>
      <p
        className={classNames('flex justify-between cursor-pointer', {
          'py-6': !isOpen,
          'pt-6 pb-0': isOpen
        })}
        onClick={handleClick}
      >
        <span className='text-lg'>{header}</span>
        <ChevronDown className={classNames(`fill-current text-primary w-4 h-2 ml-4`, { 'transform rotate-180': isOpen })} />
      </p>
      <div
        className={classNames({
          'transition-all duration-300 smooth': canAnimate,
          'delay-150': !isOpen && canAnimate
        })}
        style={{
          maxHeight: canAnimate ? (!isOpen ? '0' : `${bodyHeight}px`) : (!isOpen ? '0' : 'none')
        }}
      >
        <div
          ref={bodyElement}
          className={classNames('pt-4', {
            'opacity-0 pointer-events-none': !isOpen,
            'opacity-100': isOpen,
            'transition-opacity': canAnimate,
            'duration-150 delay-300': isOpen && canAnimate
          })}
        >
          {body}
        </div>
      </div>
    </li>
  )
}

FaqsItem.propTypes = {
  header: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]).isRequired,
  body: PropTypes.element.isRequired,
  isOpen: PropTypes.bool,
  canAnimate: PropTypes.bool
}

export default FaqsItem
